<template>
  <div v-if="reqDetail !== null" class="mt-4">
    <div  class="relative mb-4 card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full ">
      <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center" v-if="reqDetail.taskTitle !== ''">{{reqDetail.taskTitle}}</h1>
        <h1 class="heading-1 text-center" v-else>Job #{{reqDetail.taskId}}</h1>
      </div>
      <div class="absolute right-2 top-2">
        <div class="relative">
          <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
            <i class="fas fa-ellipsis-v"></i>
          </div>
          <div v-if="showMenuList">
            <div v-click-outside="closeList">
              <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10" :class="reqDetail.isTaskCompleted ? 'w-64' : 'w-32'">
                  <div class="p-2 text-text2 font-medium text-center">
                    <div class="p-2 cursor-pointer hover:bg-blue-50" @click="editRequest()">
                      <span class="py-1">Edit</span>
                    </div>
                    <div v-if="reqDetail.isTaskCompleted" class="p-2 cursor-pointer hover:bg-blue-50" @click="completeTaskA()">
                      <span class="py-1">Mark as UnCompleted</span>
                    </div>
                    <div class="p-2 cursor-pointer hover:bg-red-50" @click="deleteFun()">
                      <span class="py-1 text-error">Delete</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 flex flex-wrap gap-4 card rounded-xl bg-white p-4">
      <Button v-if="!reqDetail.isTaskCompleted" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Complete Task'" @buttonAction="completeTaskA"/>
      <Button v-if="reqDetail.isTaskCompleted" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Task Completed'" :isCurNormal="true"/>
    </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class="relative">
              <p>Task #{{reqDetail.taskId}}</p>
              <!-- <span class=" text-primary cursor-pointer" v-if="reqDetail.customerName !== ''" @click.stop="customerDetail">{{reqDetail.customerName}} ({{ reqDetail.isLead ? 'Lead' : 'Customer' }})</span> -->
              <div class="flex justify-between">
                <div class="">
                <span class="cursor-pointer text-primary font-semibold" @click.stop="customerDetail()">{{reqDetail.customerName}} ({{ reqDetail.isLead ? 'Lead' : 'Customer' }})</span>
                </div>
                <div v-if="reqDetail.customerHotNotes !== ''" class=" text-right">
                  <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes"> {{showHotNOtes ? 'Hide' : 'Show'}} hot notes</span>
                </div>
              </div>
              <div v-if="showHotNOtes" class="">
                  <span class="cursor-pointer text-secondary">
                    {{reqDetail.customerHotNotes}}
                  </span>
                </div>
              <p class="">Task Date: {{reqDetail.taskDate | dayDateType }}</p>
            </div>
          </div>
          <div v-if="reqDetail.latitude !== 0 && reqDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4 text-text1">
            <div class="flex justify-between items-center mb-2">
              <p class="font-bold">Address</p>
            </div>
            <div class="">
              <div class="flex whitespace-nowrap justify-between">
                  <div class="flex items-start">
                    <div>
                      <span @click="openMap(reqDetail.latitude, reqDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2">:</span></span>
                    </div>
                    <div class="pl-1 relative">
                        <p v-if="reqDetail.fullAddress !== ''" class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="showJobDetail = !showJobDetail">
                        {{reqDetail.fullAddress}}
                        </p>
                        <p v-else class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap" @click="showJobDetail = !showJobDetail">
                          <span v-if="reqDetail.addressLine1 !== ''">{{reqDetail.addressLine1}}, </span> 
                          <span v-if="reqDetail.addressLine2 !== ''">{{reqDetail.addressLine2}}, </span> 
                          <span v-if="reqDetail.city !== ''">{{reqDetail.city}}, </span> 
                          <span v-if="reqDetail.state !== ''">{{reqDetail.state}}, </span> 
                          <span v-if="reqDetail.country !== ''">{{reqDetail.country}}-</span> 
                          <span v-if="reqDetail.zip !== ''">{{reqDetail.zip}}</span>
                        </p>
                        <div v-if="showJobDetail">
                          <p  class="text-text1 heading-6">
                            <span v-if="reqDetail.houseNo !== ''">#: {{reqDetail.houseNo}}</span>
                            <span v-if="reqDetail.houseNo !== ''"> (Floor: {{reqDetail.floorNumber}})</span>
                          </p>
                          <p v-if="reqDetail.buildingName!== ''" class="text-text1 ">Bldg: {{reqDetail.buildingName}}</p>
                          <p v-if="reqDetail.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{reqDetail.directionNote}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="flex items-start">
                      <span @click="showJobDetail = !showJobDetail" v-if="showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                      <p @click="showJobDetail = !showJobDetail" v-if="!showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                    </div>
                </div>
            </div>
          </div>
          <div v-if="reqDetail.latitude === 0 && reqDetail.longitude === 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="">
              <div class="flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="text-gray4 heading-5">At Office</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="font-bold mb-2">Assign member(s)</p>
              <div class="flex gap-2 flex-wrap">
                <div class="flex flex-wrap" v-for="(user, index) in reqDetail.assignToList" :key="index">
                  <Chip v-if="activeUer !== user.userDetailId" :chipText="`${user.firstName}  ${user.lastName}`"/>
                  <Chip v-else :chipText="`Me`"/>
                </div> 
              </div>
              <p class="pt-2"><i class="fa-solid fa-bell text-primary"></i> {{this.checkTime(reqDetail.reminderBeforeMinutes)}}</p>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="font-bold">Schedule</p>
              <p v-if="!allDay" class="text-gray4">{{reqDetail.startTime | logTimeShow}} - {{reqDetail.endTime | leaveTimeFilterForActivity}}</p>
              <p v-else class="text-gray4">{{reqDetail.startTime | allDayTimeFill}}: Full-Day</p>
              <p class="text-success font-bold mt-2" v-if="reqDetail.isCompleted">Visit Completed</p>
            </div>
          </div>
          <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p v-if="reqDetail.taskDescription !== ''" class="text-text1 font-bold mb-2 heading-4 ">Instructions</p>
              <p v-else class="text-text1 heading-4 ">No Instructions</p>
              <p class="whitespace-pre-line">{{reqDetail.taskDescription}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmBox v-if="deleteReqConfirm"  :message="'Want to delete this request'" :title="'Are you sure?'" />
    <div v-if="deleteReqConfirmwithSel" class="popup_overlay relative px-4">
      <div style="width: 34 0px;" class="custom_dialog rounded-xl h-min p-4 text-center">
        <div class="mb-2">
          <p class="heading-3 text-text1">This is a recurring task</p>
        </div>
        <div class="divider"></div>
        <div class="">
          <p class="heading-4 text-gray4 my-2 cursor-pointer hover:bg-gray-50" @click="deleteTaskFunc(false)">Delete only this task</p>
          <p class="heading-4 text-gray4 cursor-pointer hover:bg-gray-50" @click="deleteTaskFunc(true)">Delete this and all future tasks</p>
        </div>
        <div  class="mb-5">
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="deleteReqConfirmwithSel = false"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {dateSuffixFilter} from '@/utils/dateEndVal.js'
var moment = require('moment')
import Chip from '@/View/components/chip.vue';
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import {FilterPermissions} from '@/utils/Permissions.js'

export default {
  name: "lead",
  components: {
    ConfirmBox,
    Chip,
    Button,
  },
  data() {
    return {
      showHotNOtes: false,
      showJobDetail: false,
      temp: FilterPermissions(''),
      isCustomerPermission: FilterPermissions('customer'),
      isLeadPermission: FilterPermissions('lead'),
      weekDayList: [
        {
          displayVal: 'S',
          dayVal: 0,
          isSelected: false,
          fullName: 'Sun',
        },
        {
          displayVal: 'M',
          dayVal: 1,
          isSelected: false,
          fullName: 'Mon',
        },
        {
          displayVal: 'T',
          dayVal: 2,
          isSelected: false,
          fullName: 'Tue',
        },
        {
          displayVal: 'W',
          dayVal: 3,
          isSelected: false,
          fullName: 'Wed',
        },
        {
          displayVal: 'T',
          dayVal: 4,
          isSelected: false,
          fullName: 'Thu',
        },
        {
          displayVal: 'F',
          dayVal: 5,
          isSelected: false,
          fullName: 'Fri',
        },
        {
          displayVal: 'S',
          dayVal: 6,
          isSelected: false,
          fullName: 'Sat',
        },
      ],
      scheduleDate: '',
      showMenuList: false,
      overView: null,
      reqDetail: null,
      orgDetail: null,
      anyTime: false,
      addUpdateshedule: false,
      morning: false,
      afternoon: false,
      evening: false,
      deleteReqConfirm: false,
      deleteReqConfirmwithSel: false,
      allDay: false,
      logedInUser: 0,
      activeUer: 0,
      displayText: '',
      reminderList: [
        {remId: 0, title: 'No reminder set', value: -1},
          {remId: 1, title: 'At start of task', value: 0},
          {remId: 2, title: '5 min prior', value: 5},
          {remId: 3, title: '10 min prior', value: 10},
          {remId: 4, title: '15 min prior', value: 15},
          {remId: 5, title: '30 min prior', value: 30},
          {remId: 6, title: '1 hour prior', value: 60},
          {remId: 7, title: '2 hours prior', value: 120},
          {remId: 8, title: '5 hours prior', value: 300},
          {remId: 9, title: '12 hours prior', value: 720},
          {remId: 10, title: '24 hours prior', value: 1440}
      ],
    };
  },
  created() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  mounted() {
    setTimeout(() => {
      let temp = FilterPermissions('task')
      console.log('temptemp', temp)
      if (temp) {
        this.scheduleDate = this.$route.query.date
        let detailObj = JSON.parse(localStorage.getItem('jobUserInfo'))
        this.logedInUser = detailObj.userDetailId
        this.getDetail()
        this.$root.$on('confirmBoxHandler', (data) => {
          if (data && this.deleteReqConfirm) {
            this.deleteTaskFunc(false)
          }
          document.body.style = 'overflow: visible;'
          this.deleteReqConfirm = false
        })
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to show Task detail`})
      }
    }, 200);
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
  },
  methods: {
    closeList () {
      this.showMenuList = false
    },
    convertToQuote () {
      this.$router.push({name: 'EditQuote', params: {quoteId: 0} , query: {customerId: this.reqDetail.customerId}})
    },
    customerDetail () {
      if (this.isCustomerPermission || this.isLeadPermission) {
        this.$router.push({name: 'customerDetails', params: {customerId: this.reqDetail.customerId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission open Customer Detail`})
      }
    },
    completeTaskA () {
    this.showMenuList = false
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
    this.reqDetail.isTaskCompleted = !this.reqDetail.isTaskCompleted
      MyJobApp.completeTask(
        parseInt(this.$route.params.taskId),
        this.reqDetail.isTaskCompleted,
        moment(new Date(this.scheduleDate)).format('YYYY-MM-DD'),
        (response) => {
          this.getDetail()
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    deleteTaskFunc (value) {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.DeleteTask(
        parseInt(this.$route.params.taskId),
        value,
        moment(new Date(this.scheduleDate)).format('YYYY-MM-DD'),
        (response) => {
          this.$router.push({ name: 'Tasks' });
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    checkTime (data) {
      let dataA = ''
      this.reminderList.forEach(element => {
        if (element.value === parseInt(data)) {
          dataA = element.title
        }
      });
      return dataA
    },
    deleteFun () {
      if (!this.reqDetail.isRepeated) {
        this.deleteReqConfirm = true
      } else {
        this.deleteReqConfirmwithSel = true
      }
    },
    editRequest () {
      this.$router.push({name: 'UpdateNewTask', params: {taskId: parseInt(this.$route.params.taskId)}, query: {date: this.scheduleDate}})
    },
    getDetail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.GetTaskDetail(
        parseInt(this.$route.params.taskId),
        moment(new Date(this.scheduleDate)).format('YYYY-MM-DD'),
        (response) => {
          this.reqDetail = response.Data
          if (this.reqDetail.latitude === 0 && this.reqDetail.longitude === 0) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
            MyJobApp.getOrgDetail(
              (response) => {
                this.orgDetail = response.Data
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              },
              (err) => {
                this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }
            );
          }
         if (response.Data.startTime !== "" && response.Data.endTime) {
          var startTime = moment(response.Data.startTime,)
          var endTime = moment(response.Data.endTime,)
          var hoursDiff = endTime.diff(startTime, 'hours')
          console.log("hoursDiff", hoursDiff);
          var minutesDiff = endTime.diff(startTime, 'minutes') % 60
          console.log("minutesDiff", minutesDiff);
          if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
            this.allDay = true
          } else {
            this.allDay = false
          }
         }
        this.displayText = '';
        const everyRepeatCount = this.reqDetail.repeatEvery;
        console.log('this.reqDetail.taskRepeatRule', this.reqDetail)
        const repeatType = this.reqDetail.taskRepeatRule.repeatType
        const repeatRule = this.reqDetail.taskRepeatRule.repeatRule
        const repeatArr = repeatRule ? JSON.parse(repeatRule) : null;
        console.log('repeatArr', repeatArr);
        this.$store.dispatch("SetLoader", { status: false, message: "" });
        if (parseInt(this.reqDetail.repeatEvery, 32) <= 0) {
          return false;
        }
        if (repeatType === 'd') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Daily'
              : `Every ${everyRepeatCount} days`;
        } else if (repeatType === 'w') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Weekly on '
              : `Every ${everyRepeatCount} weeks on `;
          if (repeatRule.length === 0) {
            return false;
          }
          repeatArr.map((x, index) => {
            // this.displayText += index === repeatArr.length - 1 ? 'and ' : '';
            this.displayText +=
              index === repeatArr.length - 1 && repeatArr.length > 1
                ? ' & '
                : index > 0
                ? ', '
                : '';
            this.displayText += `${this.weekDayList[x].fullName}`;
          });
        } else if (repeatType === 'm') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Monthly on '
              : `Every ${everyRepeatCount} month on `;
          if (repeatArr.length === 0) {
            return false;
          }
          repeatArr.map((x, index) => {
            this.displayText +=
              index === repeatArr.length - 1 && repeatArr.length > 1
                ? ' & '
                : index > 0
                ? ', '
                : '';
            this.displayText += `${x > -1 ? x : 'Last Day'}${
              x > -1 ? dateSuffixFilter(x) : ''
            }`;
          });
        } else if (repeatType === 'wd') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Monthly on'
              : `Every ${everyRepeatCount} month on`;
          const fstWeek = [];
          const sWeek = [];
          const tWeek = [];
          const fWeek = [];
          for (const day in repeatArr) {
            console.log('repeatArr day', repeatArr[day]);
            repeatArr[day].map(x => {
              console.log('Week numbers for ', day, x);
              switch (x) {
                case 1:
                  fstWeek.push(day);
                  break;
                case 2:
                  sWeek.push(day);
                  break;
                case 3:
                  tWeek.push(day);
                  break;
                case 4:
                  fWeek.push(day);
                  break;
                default:
                  break;
              }
            });
          }
          let isValid = false;
          if (fstWeek.length > 0) {
            console.log('fstWeek', fstWeek);
            isValid = true;
            this.displayText += ' 1st Week (';
            fstWeek.map((x, index) => {
              console.log('First week array ', x);
              this.displayText +=
                index === fstWeek.length - 1 && fstWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += '),';
          }
          if (sWeek.length > 0) {
            isValid = true;
            this.displayText += ' 2nd Week (';
            sWeek.map((x, index) => {
              console.log('Second week array ', x);
              this.displayText +=
                index === sWeek.length - 1 && sWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += '),';
          }
          if (tWeek.length > 0) {
            isValid = true;
            this.displayText += ' 3rd Week (';
            tWeek.map((x, index) => {
              console.log('Third week array ', x);
              this.displayText +=
                index === tWeek.length - 1 && tWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += '),';
          }
          if (fWeek.length > 0) {
            isValid = true;
            this.displayText += ' 4th Week (';
            fWeek.map((x, index) => {
              console.log('Fourth week array ', x);
              this.displayText +=
                index === fWeek.length - 1 && fWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += ')';
          }
          console.log('Repeat this.displayText', isValid);
        }
        console.log('Repeat this.displayText', this.displayText);
        this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
  },
};
</script>
<style scoped>
</style>